@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-secondary;
  }
  .h1 {
    @apply text-2xl font-bold leading-[1.15] md:text-3xl;
  }
  .h2 {
    @apply text-xl font-bold leading-[1.25]  md:text-2xl;
  }
  .h3 {
    @apply text-md font-bold leading-[1.25] md:text-lg;
  }
}

.section-padding-primary {
  @apply py-[3.75rem] md:py-[6.25rem] xl:py-[7.5rem];
}

::selection {
  text-shadow: none;
  @apply bg-primary text-white;
}
